/*
    Buy Membership Block
*/
.buy-membership-block {
    .info-banner {
        background-color: $color-dark-opac-2;
        padding: rem(20);

        p {
            color: $color-light;
            margin-bottom: rem(0);
        }

        p.tint-text {
            color: $color-light-shade-4;
        }
    }

    .error-banner {
        .error-message-body {
            margin: rem(20) 0;
            text-align: center;
        }

        .error-message-action-url {
            display: block;
            text-align: center;
        }

        svg {
            display: block;
            width: rem(35);
            height:rem(35);
            margin-right: auto;
            margin-bottom: rem(20);
            margin-left: auto;

            color: $color-light;

            background-color: $color-error;
            border: rem(6) solid $color-error;
            border-radius: 50%;
        }

        &-body {
            margin-top: rem(20);
            margin-bottom: rem(10);

            font-size: rem(18);
            text-align: center;

            @media (--from-small-screen-w) {
                margin-bottom: rem(30);
            }
        }

        &-action-url {
            display: block;
            margin-bottom: rem(20);

            font-size: rem(18);
            text-align: center;
        }
    }

    // Re-used elements
    button.edit-btn {
        display: inline-block;
        color: $color-primary;
        margin-left: rem(10);
        text-decoration: underline;
        font-size: rem(16);
    }

    p.no-margin {
        margin-bottom: rem(0);
    }

    .section {
        margin-bottom: rem($block-spacing-large);
    }

    .title {
        font-weight: $weight-semibold;
        margin-bottom: rem(8);

        // Types
        &.with-tail {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            @media (--from-small-screen-w) {
                justify-content: center;
            }

            &::after {
                display: none;
                flex: 1;
                content: '';
                height: rem(2);
                background-color: $color-light-shade-3;
                margin-left: rem(20);

                @media (--from-small-screen-w) {
                    display: block;
                }
            }
        }

        small {
            display: inline-block;
            margin: 0 rem(10);
            color: $color-dark-tint-2;
        }

        .icon {
            display: inline-block;
            margin: 0 rem(10);
            width: rem(20);
            height: rem(20);
            color: $color-primary;
        }
    }

    .subtitle {
        font-size: rem(14);
    }

    .totle-price {
        font-size: rem(18);
        font-weight: $weight-semibold;
        text-align: right;
        margin-bottom: 0;
        padding: rem(5) rem(20);

        &.primary {
            color: $color-primary;
        }

        &.secondary {
            color: $color-dark;
        }

        &.bg-primary {
            background-color: $color-primary;
        }

        & > span {
            display: inline-block;
            margin: 0 rem(10);
        }

        &.has-discount > span {
            text-decoration: line-through;
        }
    }

    hr {
        border-color: $color-light-shade-3;
    }

    // Review
    .review-order {
        // Gifter and recipient details
        .contact {
            p > span {
                display: inline-block;
                min-width: rem(100);
            }
        }
    }

    .discount-info {
        display: flex;
        flex-direction: column;

        @media (--from-small-screen-w) {
            flex-direction: row;
            align-items: center;
        }

        .name {
            flex: 1;
            font-weight: $weight-semibold;
            margin-bottom: rem(10);

            @media (--from-small-screen-w) {
                margin-bottom: 0;
            }
        }
    }

    // Order receipt on complete page
    .complete-order {
        .title {
            text-align: center;
        }
    }
}